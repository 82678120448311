<template>
  <div class="animated fadeIn">
    <b-card>
      <b-tabs content-class="mt-3" justified v-model="activeTab">
        <!--<b-tab title="Details" v-if="$permitted('tab:UserDetails').visible"> -->
        <b-tab title="Details">
          <user-details-form
            ref="detailsForm"
            :active="activeTab === 0"
            :id="profile.data.id"
            :mode="FORM_MODE.VIEW"
            :is-profile="true"
          />
        </b-tab>

        <!--<b-tab title="Salary" v-if="$permitted('tab:UserSalary').visible">-->
        <b-tab title="Salary">
          <user-salary-form
            ref="salaryForm"
            :active="activeTab === 1"
            :id="profile.data.id"
            :mode="FORM_MODE.READONLY"
          />
        </b-tab>
        <b-tab title="Benefits">
          <user-benefits
            ref="benefits"
            :active="activeTab === 2"
            :id="profile.data.id"
            :mode="FORM_MODE.READONLY"
          />
        </b-tab>
        <b-tab title="Permissions" style="height: 100vh;overflow: auto;">
          <user-permissions-form
            ref="permissionsForm"
            :active="activeTab === 3"
            :id="profile.data.id"
            :mode="FORM_MODE.READONLY"
            :is-embeded="false"
          />
        </b-tab>

        <b-tab title="Notifications">
          <user-notifications-form
            ref="notificationsForm"
            :active="activeTab === 4"
            :id="profile.data.id"
            :mode="FORM_MODE.VIEW"
          />
        </b-tab>
        <b-tab title="CRM activities">
          <user-activity-tracking-form
            ref="userActivityTrackingForm"
            :active="activeTab === 5"
            :id="profile.data.id"
            :mode="FORM_MODE.VIEW"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { FORM_MODE } from "@/shared/constants.js";

import UserDetailsForm from "@/views/UserManagement/UserDetailsForm";
import UserSalaryForm from "@/views/UserManagement/UserSalaryForm";
import UserPermissionsForm from "@/views/UserManagement/UserPermissionsForm";
import UserNotificationsForm from "@/views/UserManagement/UserNotificationsForm";
import UserActivityTrackingForm from "@/views/UserManagement/UserActivityTrackingForm";
import UserBenefits from "@/views/UserManagement/UserBenefits";
//import Vue from 'vue';

export default {
  name: "Profile",
  components: {
    UserDetailsForm,
    UserSalaryForm,
    UserPermissionsForm,
    UserNotificationsForm,
    UserActivityTrackingForm,
    UserBenefits
  },

  computed: mapState({
    profile: state => state.profile
  }),

  data: function() {
    return {
      isLoading: false,
      FORM_MODE: FORM_MODE,
      activeTab: 0,
      controls: {
        modal: {
          message: ""
        },
        alert: {
          show: 0,
          message: ""
        }
      }
    };
  },
  created() {},
  mounted() {
    this.fetchProfile();
  },
  methods: {
    ...mapActions("profile", ["fetchProfile", "updateProfile"]),

    save() {
      this.updateProfile(this.profile.data)
        .then(() => {
          self.$form.makeToastInfo("Updated successfully");
        })
        .catch(() => {
          self.$form.makeToastError("Error occured");
        });
    }
  }
};
</script>

<style></style>
