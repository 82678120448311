<template>
  <div>
    <DetailsFormWrapper
      ref="form"
      :id="id"
      :model="model"
      :data="data"
      :read-only="mode === $constants.FORM_MODE.READONLY"
      :mode="mode"
      header="Benefit programs"
      @loaded="onFormLoad"
    >
      <slot name="content">
        <table-wrapper
          :ref="model.name"
          :model="model"
          :parent-id="id"
          actions-mode="event"
          :columns="tableColumns"
          :read-only="mode === $constants.FORM_MODE.READONLY"
          :filter-by-column="false"
          :slots="['Benefit Name']"
          @updated="onItemsChange"
        >
          <div slot="Benefit Name" slot-scope="props">
            <b-link
              :href="`${$client_url}${props.row['route_url']}`"
              target="_blank"
            >
              {{ props.row["Benefit Name"] }}
            </b-link>
          </div>
        </table-wrapper>
      </slot>
    </DetailsFormWrapper>
  </div>
</template>

<script>
import models from "@/models";
import TableWrapper from "@/components/DataView/TableWrapper";

export default {
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    mode: {
      type: [Number],
      default: 0
    },
    active: {
      type: [Number, Boolean],
      default: 0
    }
  },
  name: "UserBenefits",
  components: { TableWrapper },
  data: function() {
    return {
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      model: models.userManagement.userBenefits,

      tableColumns: ["ID", "Benefit Name", "Eligibility Date", "Enrolled"],
      data: {}
    };
  },
  created() {},
  mounted() {
    this.initialize();
  },
  methods: {
    initialize: async function() {
      this.updateControlsState();

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        this.$refs[this.model.name].getData();
      }
    },
    onItemsChange(e) {
      this.data.user_id = this.id;
      this.data.benefits = e;

      if (this.$form.mode(this) === this.$constants.FORM_MODE.VIEW)
        this.$refs["form"]
          .save("view")
          .then(() => this.$refs["form"].initialize());
    },
    onFormLoad() {},

    updateControlsState() {
      let _readonly = false;

      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        _readonly = false;
      }

      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        _readonly = true;
      }

      for (var prop in this.controls) {
        if (Object.prototype.hasOwnProperty.call(this.controls, prop)) {
          this.controls[prop].readonly = _readonly;
        }
      }
    }
  },
  watch: {}
};
</script>

<style scoped>
::v-deep .VueTables__table {
  overflow: visible !important;
}
::v-deep .table-responsive {
  overflow: visible !important;
}
</style>
