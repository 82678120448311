<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pt-3">
        <b-row>
          <b-col lg="12" class="d-flex align-items-end">
            <g-chart-custom
              ref="chart1"
              :default-mode="charts.chart1.isDefaultMode"
              title=""
              :type="charts.chart1.type"
              :loading="charts.chart1.loading"
              :data="charts.chart1.chartData"
              :options="charts.chart1.chartOptions"
            >
            </g-chart-custom>
          </b-col>
        </b-row>
        <b-row v-if="dataTable.visible">
          <b-col>
            <user-activity-tracking-table ref="user-activity-tracking" />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import UserActivityTrackingTable from "@/views/UserManagement/UserActivityTrackingTable";

import GChartCustom from "@/components/GChartCustom";

import { mapState } from "vuex";

export default {
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    mode: {
      type: Number,
      default: 0
    },
    active: {
      type: [Boolean, Number],
      default: 0
    }
  },

  name: "UserActivityTrackingForm",
  components: {
    UserActivityTrackingTable,

    GChartCustom
  },

  data: function() {
    return {
      dataTable: {
        visible: true
      },

      charts: {
        chart1: {
          type: "ColumnChart",
          pagination: {
            value: 1,
            total: 0
          },
          isDefaultMode: true,
          isColumnType: false,
          rawData: [],
          emptyStates: [],
          loading: false,
          subtitle: "",
          chartData: null,
          chartOptions: {
            height: 500,
            is3D: true,
            chartArea: {
              width: "75%",
              height: "80%"
            },
            legend: {
              //position: 'none'
            },
            vAxis: {
              textStyle: {
                fontSize: 12
              }
            }
          }
        }
      }
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      let payload = {
        user_name: [this.profile.data.full_name]
      };

      this.drawChart1();

      this.$refs["user-activity-tracking"].getData(payload);
    },

    async drawChart1() {
      this.charts.chart1.loading = true;

      let response = await this.$api.get(
        `users/${this.profile.data.id}/weekly-statistics`
      );

      this.charts.chart1.loading = false;

      this.charts.chart1.rawData = response;

      if (response.length === 0) return;

      this.updateChart1("modules");
    },

    updateChart1() {
      let headers = [
        [
          "Category",
          "Count",
          {
            type: "string",
            role: "tooltip"
          }
        ]
      ];

      let dataRow = this.charts.chart1.rawData[0];

      let data = [];

      for (const property in dataRow) {
        if (!["ID", "User name"].includes(property))
          data.push([property, +dataRow[property], property]);
      }

      this.charts.chart1.chartData = headers.concat(data);
    }
  },

  watch: {
    active(newValue) {
      //refresh chart
      if (newValue) this.drawChart1();
    }
  }
};
</script>

<style>
.fa-toolbar {
  color: #0000008a;
}
</style>
