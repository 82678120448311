var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-container', {
    staticClass: "pt-3",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-end",
    attrs: {
      "lg": "12"
    }
  }, [_c('g-chart-custom', {
    ref: "chart1",
    attrs: {
      "default-mode": _vm.charts.chart1.isDefaultMode,
      "title": "",
      "type": _vm.charts.chart1.type,
      "loading": _vm.charts.chart1.loading,
      "data": _vm.charts.chart1.chartData,
      "options": _vm.charts.chart1.chartOptions
    }
  })], 1)], 1), _vm.dataTable.visible ? _c('b-row', [_c('b-col', [_c('user-activity-tracking-table', {
    ref: "user-activity-tracking"
  })], 1)], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }