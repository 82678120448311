<template>
  <div class="form-group" v-if="visible">
    <label for="id"
      >{{ label }}
      <clip-loader class="ml-2" :size="14" color="#36D7B7" :loading="loading" />
    </label>

    <input
      v-if="!isEditMode"
      :readonly="readonly ? readonly : !isEditMode"
      type="text"
      :class="['form-control', readonly ? 'readonly' : '']"
      :value="val.label"
      @change="changeField($event)"
      @dblclick="dblclickField($event)"
    />

    <multiselect
      :class="[isInvalid && isEditMode ? 'is-invalid' : '']"
      :id="id"
      v-if="isEditMode"
      :value="val"
      track-by="id"
      label="label"
      :options="options"
      deselect-label=""
      select-label=""
      :multiple="multiple"
      :allow-empty="allowEmpty"
      @close="closeSelect"
      @input="changeSelect"
    />

    <div v-if="isInvalid && isEditMode" class="invalid-feedback d-block">
      {{ label }} is required
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import { FORMCONTROLMODE } from "@/shared/constants";

export default {
  components: {
    Multiselect
  },
  props: {
    id: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: Object,
      default: function() {
        return {
          id: "",
          label: ""
        };
      }
    },
    type: {
      type: String,
      default: "text"
    },
    loading: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },

    mode: {
      type: Number,
      default: FORMCONTROLMODE.VIEW
    },
    required: {
      type: Boolean,
      default: false
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data: function() {
    return {
      FORMCONTROLMODE: FORMCONTROLMODE,
      isEditMode: false,
      isChanged: false,
      isInvalid: false,
      val: {}
    };
  },
  computed: {},
  created: function() {
    this.val = this.value;
    this.isEditMode =
      (this.mode === FORMCONTROLMODE.EDIT ||
        this.mode === FORMCONTROLMODE.CREATE) &&
      !this.readonly;
  },
  mounted: function() {},

  methods: {
    test() {
      if (this.multiple) {
        this.isInvalid = this.required && this.val.length === 0;
      } else {
        this.isInvalid = this.required && !this.val.label;
      }

      return !this.isInvalid;
    },

    blurField(e) {
      if (this.readonly) return;

      let id = e.currentTarget.id.replace(/select:|input:/g, "");

      //this.val = e.target.value

      if (!this.test()) return;

      if (this.mode === FORMCONTROLMODE.VIEW) {
        this.isEditMode = false;
      }

      if (this.isChanged) {
        this.$emit("changed", id, this.val);
        this.$emit("input", this.val);
      }
    },
    changeField(e) {
      this.val = e.currentTarget.value;

      this.isChanged = this.value !== this.val;

      //this.isInvalid = this.required && (!e.currentTarget.value)
      this.test();
    },
    dblclickField(e) {
      if (this.isInvalid) return;
      if (this.readonly) return;

      //let id = e.currentTarget.id.replace(/select:|input:/g, "");

      if (this.mode === FORMCONTROLMODE.VIEW) {
        this.isEditMode = !this.isEditMode;

        this.isEditMode || this.blurField(e);
      }
    },
    closeSelect(value, id) {
      id = id.replace(/select:|input:/g, "");

      if (!this.test()) return;

      if (this.mode === this.FORMCONTROLMODE.VIEW) {
        this.isEditMode = !this.isEditMode;
      }

      if (this.isChanged) {
        this.$emit("changed", id, this.val);
        this.$emit("input", this.val);
      }
    },
    changeSelect(value) {
      if (this.value !== value) {
        this.isChanged = true;
      }

      this.val = value;
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal;
    }
  }
};
</script>

<style scoped>
.multiselect,
.multiselect__single,
.multiselect__tags {
  background-color: #ffffed;
  /*
 min-height: 30px;
 padding: 6px 40px 0 6px;
 font-size: 14px;
*/
}

.multiselect.is-invalid {
  border: 1px solid #f86c6b;
  border-radius: 0.25rem;
}

.readonly {
  background-color: #e8e8e873 !important;
  cursor: default !important;
}

.form-control[readonly] {
  background-color: #fff;
  font-weight: bold;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}
</style>
