var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-container', {
    staticClass: "p-3",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": !_vm.isLoaded,
      "opacity": 1,
      "bg-color": "white",
      "spinner-variant": "secondary",
      "rounded": "sm",
      "z-index": "1000"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "pt-2",
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "id": "cb-receive-daily-emails",
      "disabled": true,
      "name": "cb-receive-daily-emails",
      "value": "true",
      "unchecked-value": "false"
    },
    model: {
      value: _vm.dailyEmailsSelected,
      callback: function callback($$v) {
        _vm.dailyEmailsSelected = $$v;
      },
      expression: "dailyEmailsSelected"
    }
  }, [_vm._v(" Daily email ")]), _c('b-form-checkbox', {
    staticClass: "ml-3",
    attrs: {
      "id": "cb-daily-email-recurring-tasks",
      "name": "cb-daily-email-recurring-tasks",
      "value": "true",
      "unchecked-value": "false"
    },
    on: {
      "change": _vm.updateSettings
    },
    model: {
      value: _vm.settings.daily_emails.recurring_tasks,
      callback: function callback($$v) {
        _vm.$set(_vm.settings.daily_emails, "recurring_tasks", $$v);
      },
      expression: "settings.daily_emails.recurring_tasks"
    }
  }, [_vm._v(" Recurring tasks ")]), _c('b-form-checkbox', {
    staticClass: "ml-3",
    attrs: {
      "id": "cb-daily-email-overdue-tasks",
      "name": "cb-daily-email-overdue-tasks",
      "value": "true",
      "unchecked-value": "false"
    },
    on: {
      "change": _vm.updateSettings
    },
    model: {
      value: _vm.settings.daily_emails.overdue_tasks,
      callback: function callback($$v) {
        _vm.$set(_vm.settings.daily_emails, "overdue_tasks", $$v);
      },
      expression: "settings.daily_emails.overdue_tasks"
    }
  }, [_vm._v(" Overdue tasks ")]), _c('b-form-checkbox', {
    staticClass: "ml-3",
    attrs: {
      "id": "cb-daily-email-upcoming-tasks",
      "name": "cb-daily-email-upcoming-tasks",
      "value": "true",
      "unchecked-value": "false"
    },
    on: {
      "change": _vm.updateSettings
    },
    model: {
      value: _vm.settings.daily_emails.upcoming_tasks,
      callback: function callback($$v) {
        _vm.$set(_vm.settings.daily_emails, "upcoming_tasks", $$v);
      },
      expression: "settings.daily_emails.upcoming_tasks"
    }
  }, [_vm._v(" Upcoming tasks ")]), _c('b-form-checkbox', {
    staticClass: "ml-3",
    attrs: {
      "id": "cb-daily-email-overdue-reports",
      "name": "cb-daily-email-overdue-reports",
      "value": "true",
      "unchecked-value": "false"
    },
    on: {
      "change": _vm.updateSettings
    },
    model: {
      value: _vm.settings.daily_emails.overdue_reports,
      callback: function callback($$v) {
        _vm.$set(_vm.settings.daily_emails, "overdue_reports", $$v);
      },
      expression: "settings.daily_emails.overdue_reports"
    }
  }, [_vm._v(" Overdue reports ")]), _c('b-form-checkbox', {
    staticClass: "ml-3",
    attrs: {
      "id": "cb-daily-email-upcoming-reports",
      "name": "cb-daily-email-upcoming-reports",
      "value": "true",
      "unchecked-value": "false"
    },
    on: {
      "change": _vm.updateSettings
    },
    model: {
      value: _vm.settings.daily_emails.upcoming_reports,
      callback: function callback($$v) {
        _vm.$set(_vm.settings.daily_emails, "upcoming_reports", $$v);
      },
      expression: "settings.daily_emails.upcoming_reports"
    }
  }, [_vm._v(" Upcoming reports ")]), _c('b-form-checkbox', {
    staticClass: "ml-3",
    attrs: {
      "id": "cb-daily-email-recent-activities",
      "name": "cb-daily-email-recent-activities",
      "value": "true",
      "unchecked-value": "false"
    },
    on: {
      "change": _vm.updateSettings
    },
    model: {
      value: _vm.settings.daily_emails.recent_activities,
      callback: function callback($$v) {
        _vm.$set(_vm.settings.daily_emails, "recent_activities", $$v);
      },
      expression: "settings.daily_emails.recent_activities"
    }
  }, [_vm._v(" Recent activities ")])], 1), _c('b-col', {
    staticClass: "pt-2",
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_vm._v(" Receive copies of daily emails: "), _c('treeselect', {
    staticClass: "treeselect",
    attrs: {
      "z-index": "1",
      "multiple": true,
      "disable-branch-nodes": true,
      "open-direction": "bottom",
      "always-open": false,
      "options": _vm.usersTree.options
    },
    on: {
      "select": _vm.onUsersTreeSelect,
      "deselect": _vm.onUsersTreeDeselect
    },
    model: {
      value: _vm.settings.daily_emails.cc_users,
      callback: function callback($$v) {
        _vm.$set(_vm.settings.daily_emails, "cc_users", $$v);
      },
      expression: "settings.daily_emails.cc_users"
    }
  })], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', [_c('b-form-checkbox', {
    attrs: {
      "id": "cb-weekly-emails",
      "disabled": true,
      "name": "cb-weekly-emails",
      "value": "true",
      "unchecked-value": "false"
    },
    model: {
      value: _vm.weeklyEmailsSelected,
      callback: function callback($$v) {
        _vm.weeklyEmailsSelected = $$v;
      },
      expression: "weeklyEmailsSelected"
    }
  }, [_vm._v(" Weekly email ")]), _c('b-form-checkbox', {
    staticClass: "ml-3",
    attrs: {
      "id": "cb-weekly-email-overdue-reports",
      "name": "cb-weekly-email-overdue-reports",
      "value": "true",
      "unchecked-value": "false"
    },
    on: {
      "change": _vm.updateSettings
    },
    model: {
      value: _vm.settings.weekly_emails.overdue_reports,
      callback: function callback($$v) {
        _vm.$set(_vm.settings.weekly_emails, "overdue_reports", $$v);
      },
      expression: "settings.weekly_emails.overdue_reports"
    }
  }, [_vm._v(" Overdue reports ")]), _c('b-form-checkbox', {
    staticClass: "ml-3",
    attrs: {
      "id": "cb-weekly-email-weekly-statistics",
      "name": "cb-weekly-email-weekly-statistics",
      "value": "true",
      "unchecked-value": "false"
    },
    on: {
      "change": _vm.updateSettings
    },
    model: {
      value: _vm.settings.weekly_emails.weekly_statistics,
      callback: function callback($$v) {
        _vm.$set(_vm.settings.weekly_emails, "weekly_statistics", $$v);
      },
      expression: "settings.weekly_emails.weekly_statistics"
    }
  }, [_vm._v(" Weekly statistics ")])], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', [_c('label', {
    attrs: {
      "for": "ical-feed-url"
    }
  }, [_vm._v("iCalendar feed url:")]), _c('b-input-group', [_c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-dark"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "copy"
    },
    on: {
      "click": _vm.copyUrl
    }
  })], 1)], 1), _c('b-form-input', {
    attrs: {
      "id": "ical-feed-url"
    },
    model: {
      value: _vm.iCalFeedUrl,
      callback: function callback($$v) {
        _vm.iCalFeedUrl = $$v;
      },
      expression: "iCalFeedUrl"
    }
  })], 1)], 1)], 1)], 1), _c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      }
    },
    on: {
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Users'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'User submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'User submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }