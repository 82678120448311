<template>
  <div class="animated fadeIn">
    <b-row v-if="dataTable.dataSet.length > 0">
      <b-col lg="4">
        <b-button-group>
          <button
            class="btn btn-outline-dark btn"
            type="button"
            @click="updateView()"
          >
            <font-awesome-icon icon="list" /> Change view
          </button>
        </b-button-group>
      </b-col>
      <hr />
    </b-row>

    <table-custom
      v-show="!groupedView"
      ref="dataTable"
      :name="`custom_table_uat`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @filter="onFilter"
    >
      <div slot="Entity" slot-scope="props">
        <div
          class="d-flex justify-content-center"
          v-if="!props.row['Entity'] && props.row['Action']"
        >
          <h5>
            <b-badge pill variant="primary">
              <font-awesome-icon
                icon="plus"
                v-if="props.row['Action'] === 'create'"
              />
              <font-awesome-icon
                icon="pencil-alt"
                v-if="props.row['Action'] === 'edit'"
              />
              <font-awesome-icon
                icon="eye"
                v-if="props.row['Action'] === 'view'"
              />
            </b-badge>
          </h5>
        </div>

        <div class="d-flex justify-content-center" v-if="props.row['Entity']">
          <h5>
            <b-badge pill variant="primary" :to="props.row.entity_url">
              <font-awesome-icon
                icon="plus"
                v-if="props.row['Action'] === 'create'"
              />
              <font-awesome-icon
                icon="pencil-alt"
                v-if="props.row['Action'] === 'edit'"
              />
              <font-awesome-icon
                icon="eye"
                v-if="props.row['Action'] === 'view'"
              />
              <span class="p-1"> {{ props.row['Entity'] }} </span>
            </b-badge>
          </h5>
        </div>
      </div>
    </table-custom>

    <table-custom
      v-show="groupedView"
      ref="dataTableGrouped"
      :name="`custom_table_uat_grouped`"
      :loading="dataTableGrouped.isLoading"
      :data="dataTableGrouped.dataSet"
      :options="dataTableGrouped.options"
      @filter="onFilter"
    />
  </div>
</template>

<script>
import moment from 'moment'

import TableCustom from '@/components/TableCustom'

export default {
  name: 'UserActivityTrackingTable',
  props: {
    userId: {
      type: [String, Number],
      default: undefined
    }
  },
  components: {
    TableCustom
  },
  data: function () {
    return {
      groupedView: false,
      isReviewer: false,
      rawData: {},
      detailedData: [],
      groupedData: [],
      dataTable: {
        visible: true,
        isLoading: false,
        options: {
          uniqueKey: 'ID',
          showChildRowToggler: false,
          filterByColumn: false,
          columns: [
            //'p1','p2',
            'User Name',
            'Page',
            'Module',
            'Entity',
            //'Action',
            'Tracking Date'
          ],
          slots: ['Entity'],
          perPage: 50,
          disablePerPageDropdown: false,
          perPageValues: [],
          showCustomActions: false,
          showChildRows: false
        },

        dataSet: [],
        onRowClick: function () {},
        totalRecords: 0
      },
      dataTableGrouped: {
        visible: true,
        isLoading: false,
        options: {
          uniqueKey: 'ID',
          showChildRowToggler: false,
          filterByColumn: false,
          columns: ['User Name', 'Period', 'Created', 'Viewed', 'Edited'],
          perPage: 50,
          disablePerPageDropdown: false,
          perPageValues: [],
          showCustomActions: false,
          showChildRows: false
        },

        dataSet: [],
        onRowClick: function () {},
        totalRecords: 0
      }
    }
  },
  computed: {},
  mounted () {
    if (this.userId) this.getData({ user_id: this.userId })
  },
  methods: {
    updateView () {
      this.groupedView = !this.groupedView
    },
    async getData (payload) {
      let self = this

      //display spinner  by default
      if (!payload || !payload.silentUpdate) {
        self.dataTable.isLoading = true

        self.dataTable.dataSet = []

        self.dataTableGrouped.isLoading = true
      }

      this.detailedData = await this.$api.post(
        'user/activity-tracking/details',
        payload
      )

      self.dataTable.isLoading = false

      this.groupedData = await this.$api.post(
        'user/activity-tracking/grouped',
        payload
      )

      self.dataTableGrouped.isLoading = false

      self.$emit('loaded', this.detailedData.length)

      self.dataTable.dataSet = self.formatData(this.detailedData)

      self.dataTableGrouped.dataSet = this.groupedData

      if (this.detailedData.length === 0) return

      /*
            return this.$api.post('user/activity-tracking/details', payload).then(response => {

                self.dataTable.isLoading = false;

                self.$emit("loaded", response.length)

                self.dataTable.dataSet = self.formatData(response);

                if (response.length === 0) return;

            }).catch(error => {

                console.log(error)
                self.dataTable.isLoading = false;
                self.$form.msgBoxOk('Error occured')

            });
*/
    },
    formatData (data) {
      data.forEach(row => {
        if (row.entity_id !== '') {
          if (row.Module === 'Accounts')
            row.entity_url = `/relationship-management/account/view/${row.entity_id}`

          if (row.Module === 'Activities')
            row.entity_url = `/account-touchpoints/activity/view/${row.entity_id}`

          if (row.Module === 'Contacts')
            row.entity_url = `/relationship-management/contact/view/${row.entity_id}`

          if (row.Module === 'Invoices')
            row.entity_url = `/sales-administrative/invoice/view/${row.entity_id}`

          if (row.Module === 'Samples')
            row.entity_url = `/pos-and-samples/sample-request/view/${row.entity_id}`

          if (row.Module === 'Barrels')
            row.entity_url = `/pos-and-samples/barrel/view/${row.entity_id}`

          if (row.Module === 'Tasks')
            row.entity_url = `/project-management/task/view/${row.entity_id}`

          if (row.Module === 'Reports')
            row.entity_url = `/reports/report-submission/view/${row.entity_id}`

          if (row.Module === 'Incentives')
            row.entity_url = `/incentives/incentive/view/${row.entity_id}`
        }

        row['Tracking Date'] = moment
          .tz(moment(row['Tracking Date']), 'America/Chicago')
          .format('YYYY-MM-DD HH:mm:ss')
      })

      return data
    },
    onFilter () {
      this.dataTable.totalRecords = this.$refs.dataTable.data.length
    }
  }
}
</script>

<style scoped></style>
