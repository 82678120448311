<template>
  <div class="animated fadeIn">
    <b-container fluid class="p-3">
      <b-overlay
        :show="!isLoaded"
        :opacity="1"
        bg-color="white"
        spinner-variant="secondary"
        rounded="sm"
        z-index="1000"
      >
        <b-row>
          <b-col cols="12" lg="4" class="pt-2">
            <b-form-checkbox
              id="cb-receive-daily-emails"
              :disabled="true"
              v-model="dailyEmailsSelected"
              name="cb-receive-daily-emails"
              value="true"
              unchecked-value="false"
            >
              Daily email
            </b-form-checkbox>

            <b-form-checkbox
              class="ml-3"
              id="cb-daily-email-recurring-tasks"
              v-model="settings.daily_emails.recurring_tasks"
              name="cb-daily-email-recurring-tasks"
              value="true"
              unchecked-value="false"
              @change="updateSettings"
            >
              Recurring tasks
            </b-form-checkbox>

            <b-form-checkbox
              class="ml-3"
              id="cb-daily-email-overdue-tasks"
              v-model="settings.daily_emails.overdue_tasks"
              name="cb-daily-email-overdue-tasks"
              value="true"
              unchecked-value="false"
              @change="updateSettings"
            >
              Overdue tasks
            </b-form-checkbox>

            <b-form-checkbox
              class="ml-3"
              id="cb-daily-email-upcoming-tasks"
              v-model="settings.daily_emails.upcoming_tasks"
              name="cb-daily-email-upcoming-tasks"
              value="true"
              unchecked-value="false"
              @change="updateSettings"
            >
              Upcoming tasks
            </b-form-checkbox>

            <b-form-checkbox
              class="ml-3"
              id="cb-daily-email-overdue-reports"
              v-model="settings.daily_emails.overdue_reports"
              name="cb-daily-email-overdue-reports"
              value="true"
              unchecked-value="false"
              @change="updateSettings"
            >
              Overdue reports
            </b-form-checkbox>

            <b-form-checkbox
              class="ml-3"
              id="cb-daily-email-upcoming-reports"
              v-model="settings.daily_emails.upcoming_reports"
              name="cb-daily-email-upcoming-reports"
              value="true"
              unchecked-value="false"
              @change="updateSettings"
            >
              Upcoming reports
            </b-form-checkbox>

            <b-form-checkbox
              class="ml-3"
              id="cb-daily-email-recent-activities"
              v-model="settings.daily_emails.recent_activities"
              name="cb-daily-email-recent-activities"
              value="true"
              unchecked-value="false"
              @change="updateSettings"
            >
              Recent activities
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" lg="4" class="pt-2">
            Receive copies of daily emails:
            <treeselect
              class="treeselect"
              v-model="settings.daily_emails.cc_users"
              z-index="1"
              :multiple="true"
              :disable-branch-nodes="true"
              open-direction="bottom"
              :always-open="false"
              :options="usersTree.options"
              @select="onUsersTreeSelect"
              @deselect="onUsersTreeDeselect"
            />
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-form-checkbox
              id="cb-weekly-emails"
              :disabled="true"
              v-model="weeklyEmailsSelected"
              name="cb-weekly-emails"
              value="true"
              unchecked-value="false"
            >
              Weekly email
            </b-form-checkbox>
            <b-form-checkbox
              class="ml-3"
              id="cb-weekly-email-overdue-reports"
              v-model="settings.weekly_emails.overdue_reports"
              name="cb-weekly-email-overdue-reports"
              value="true"
              unchecked-value="false"
              @change="updateSettings"
            >
              Overdue reports
            </b-form-checkbox>
            <b-form-checkbox
              class="ml-3"
              id="cb-weekly-email-weekly-statistics"
              v-model="settings.weekly_emails.weekly_statistics"
              name="cb-weekly-email-weekly-statistics"
              value="true"
              unchecked-value="false"
              @change="updateSettings"
            >
              Weekly statistics
            </b-form-checkbox>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <label for="ical-feed-url">iCalendar feed url:</label>
            <b-input-group>
              <b-input-group-append>
                <b-button variant="outline-dark">
                  <font-awesome-icon icon="copy" @click="copyUrl" />
                </b-button>
              </b-input-group-append>
              <b-form-input id="ical-feed-url" v-model="iCalFeedUrl" />
            </b-input-group>
          </b-col>
        </b-row>
      </b-overlay>
      <hr />

      <form-submission-actions
        :mode="mode"
        :loading="{ save: saveInProgress, saveAndView: saveAndViewInProgress }"
        @save="
          save('tabular').then(response =>
            response ? $router.push({ name: 'Users' }) : false
          )
        "
        @save-and-view="
          save('view').then(response =>
            response
              ? $router.push({
                  name: 'User submission',
                  params: { action: 'view', id: response }
                })
              : false
          )
        "
        @edit="
          $router.push({
            name: 'User submission',
            params: { action: 'edit', id: id }
          })
        "
        @back="$router.push($store.getters['router/previousRoute'])"
      />
    </b-container>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import FormSubmissionActions from "@/components/FormSubmissionActions";

import { mapState } from "vuex";

export default {
  props: {
    id: {
      type: [Number, String],
      default: function() {
        return 0;
      }
    },
    mode: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    },
    isEmbeded: {
      type: Boolean,
      default: true
    }
  },
  name: "UsersPermissions",
  components: {
    FormSubmissionActions,
    Treeselect
  },
  data: function() {
    return {
      user_id: undefined,
      isMobile: isMobile,
      isLoading: false,
      isLoaded: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      settings: {
        daily_emails: {
          recurring_tasks: false,
          overdue_tasks: false,
          upcoming_tasks: false,
          overdue_reports: false,
          upcoming_reports: false,
          recent_activities: false,
          cc_users: []
        },
        weekly_emails: {
          overdue_reports: false,
          weekly_statistics: false
        }
      },
      users: {
        selected: {},
        options: []
      },
      usersTree: {
        value: null,
        options: []
      },
      iCalFeedUrl: ""
    };
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    }),
    dailyEmailsSelected: {
      get() {
        return (
          this.settings.daily_emails.recurring_tasks === "true" ||
          this.settings.daily_emails.overdue_tasks === "true" ||
          this.settings.daily_emails.upcoming_tasks === "true" ||
          this.settings.daily_emails.overdue_reports === "true" ||
          this.settings.daily_emails.upcoming_reports === "true" ||
          this.settings.daily_emails.recent_activities === "true"
        );
      },
      set() {
        return;
      }
    },
    weeklyEmailsSelected: {
      get() {
        return (
          this.settings.weekly_emails.recurring_reports === "true" ||
          this.settings.weekly_emails.overdue_reports === "true" ||
          this.settings.weekly_emails.weekly_statistics === "true"
        );
      },
      set() {
        return;
      }
    }
  },
  mounted() {
    this.user_id = this.id;

    this.initialize(this.user_id);
  },
  methods: {
    async initialize(_uid = false) {
      if (_uid !== false) {
        this.user_id = _uid;
      }

      if (this.user_id === 0) return;

      let self = this;

      try {
        let self = this;

        self.isLoading = true;

        let error, users;

        [error, users] = await this.$to(
          this.$api.get(`user/${this.user_id}/subordinate`)
        );
        //[error, users] = await this.$to(this.$api.get(`user/1/subordinate`))

        if (error) throw new Error(error);

        self.usersTree.options = users.map(u => ({
          id: u.id,
          label: u.full_name
        }));

        let response;

        [error, response] = await this.$to(
          this.$api.get(`users/${this.id}/notifications/settings`)
        );

        if (error) throw new Error(error);

        self.settings = JSON.parse(response);

        let url;

        [error, url] = await this.$to(
          this.$api.get(`user/${this.id}/ical/feed`)
        );

        if (error) throw new Error(error);

        self.iCalFeedUrl = url;
      } catch (error) {
        self.$form.msgBoxOk(error.message);
      } finally {
        this.isLoading = false;
        this.isLoaded = true;
      }
    },
    async copyUrl() {
      await navigator.clipboard.writeText(this.iCalFeedUrl);

      this.$form.makeToastInfo("iCalendar feed url copied");
    },
    async updateSettings() {
      try {
        // eslint-disable-next-line
        let error, response;
        // eslint-disable-next-line
        [error, response] = await this.$to(
          this.$api.put(`users/${this.id}/notifications/settings`, {
            data: JSON.stringify(this.settings)
          })
        );

        if (error) throw new Error(error);
      } catch (error) {
        self.$form.makeToastError(error.message);
      } finally {
        this.isLoading = false;
      }
    },
    onUsersTreeSelect(value) {
      if (this.mode !== this.$constants.FORM_MODE.VIEW) return;

      if (!this.settings.daily_emails.cc_users)
        this.settings.daily_emails.cc_users = [];

      this.settings.daily_emails.cc_users.push(value.id);

      console.log(this.settings.daily_emails.cc_users);

      this.updateSettings();
    },
    onUsersTreeDeselect(value) {
      if (this.mode !== this.$constants.FORM_MODE.VIEW) return;

      this.settings.daily_emails.cc_users = this.settings.daily_emails.cc_users.filter(
        v => v !== value.id
      );

      this.updateSettings();
    }
  },
  watch: {}
};
</script>

<style scoped></style>
