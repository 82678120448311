var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('DetailsFormWrapper', {
    ref: "form",
    attrs: {
      "id": _vm.id,
      "model": _vm.model,
      "data": _vm.data,
      "read-only": _vm.mode === _vm.$constants.FORM_MODE.READONLY,
      "mode": _vm.mode,
      "header": "Benefit programs"
    },
    on: {
      "loaded": _vm.onFormLoad
    }
  }, [_vm._t("content", function () {
    return [_c('table-wrapper', {
      ref: _vm.model.name,
      attrs: {
        "model": _vm.model,
        "parent-id": _vm.id,
        "actions-mode": "event",
        "columns": _vm.tableColumns,
        "read-only": _vm.mode === _vm.$constants.FORM_MODE.READONLY,
        "filter-by-column": false,
        "slots": ['Benefit Name']
      },
      on: {
        "updated": _vm.onItemsChange
      },
      scopedSlots: _vm._u([{
        key: "Benefit Name",
        fn: function fn(props) {
          return _c('div', {}, [_c('b-link', {
            attrs: {
              "href": "".concat(_vm.$client_url).concat(props.row['route_url']),
              "target": "_blank"
            }
          }, [_vm._v(" " + _vm._s(props.row["Benefit Name"]) + " ")])], 1);
        }
      }])
    })];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }