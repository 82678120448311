var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.visible ? _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "id"
    }
  }, [_vm._v(_vm._s(_vm.label) + " "), _c('clip-loader', {
    staticClass: "ml-2",
    attrs: {
      "size": 14,
      "color": "#36D7B7",
      "loading": _vm.loading
    }
  })], 1), !_vm.isEditMode ? _c('input', {
    class: ['form-control', _vm.readonly ? 'readonly' : ''],
    attrs: {
      "readonly": _vm.readonly ? _vm.readonly : !_vm.isEditMode,
      "type": "text"
    },
    domProps: {
      "value": _vm.val.label
    },
    on: {
      "change": function change($event) {
        return _vm.changeField($event);
      },
      "dblclick": function dblclick($event) {
        return _vm.dblclickField($event);
      }
    }
  }) : _vm._e(), _vm.isEditMode ? _c('multiselect', {
    class: [_vm.isInvalid && _vm.isEditMode ? 'is-invalid' : ''],
    attrs: {
      "id": _vm.id,
      "value": _vm.val,
      "track-by": "id",
      "label": "label",
      "options": _vm.options,
      "deselect-label": "",
      "select-label": "",
      "multiple": _vm.multiple,
      "allow-empty": _vm.allowEmpty
    },
    on: {
      "close": _vm.closeSelect,
      "input": _vm.changeSelect
    }
  }) : _vm._e(), _vm.isInvalid && _vm.isEditMode ? _c('div', {
    staticClass: "invalid-feedback d-block"
  }, [_vm._v(" " + _vm._s(_vm.label) + " is required ")]) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }