var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Details"
    }
  }, [_c('user-details-form', {
    ref: "detailsForm",
    attrs: {
      "active": _vm.activeTab === 0,
      "id": _vm.profile.data.id,
      "mode": _vm.FORM_MODE.VIEW,
      "is-profile": true
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Salary"
    }
  }, [_c('user-salary-form', {
    ref: "salaryForm",
    attrs: {
      "active": _vm.activeTab === 1,
      "id": _vm.profile.data.id,
      "mode": _vm.FORM_MODE.READONLY
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Benefits"
    }
  }, [_c('user-benefits', {
    ref: "benefits",
    attrs: {
      "active": _vm.activeTab === 2,
      "id": _vm.profile.data.id,
      "mode": _vm.FORM_MODE.READONLY
    }
  })], 1), _c('b-tab', {
    staticStyle: {
      "height": "100vh",
      "overflow": "auto"
    },
    attrs: {
      "title": "Permissions"
    }
  }, [_c('user-permissions-form', {
    ref: "permissionsForm",
    attrs: {
      "active": _vm.activeTab === 3,
      "id": _vm.profile.data.id,
      "mode": _vm.FORM_MODE.READONLY,
      "is-embeded": false
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Notifications"
    }
  }, [_c('user-notifications-form', {
    ref: "notificationsForm",
    attrs: {
      "active": _vm.activeTab === 4,
      "id": _vm.profile.data.id,
      "mode": _vm.FORM_MODE.VIEW
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "CRM activities"
    }
  }, [_c('user-activity-tracking-form', {
    ref: "userActivityTrackingForm",
    attrs: {
      "active": _vm.activeTab === 5,
      "id": _vm.profile.data.id,
      "mode": _vm.FORM_MODE.VIEW
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }