<template>
  <div>
    <h5>User details</h5>
    <hr />
    <b-alert v-if="isProfile" show variant="danger"
      >Please update Oleg Raskin and Sebastian Saller when any of your personal
      information changes.
    </b-alert>
    <b-row>
      <b-col sm="12" md="6" lg="3" xl="3">
        <inline-input
          :id="controls.user_name.id"
          :value="user.user_name"
          :readonly="!$permitted(controls.user_name.id).write"
          :mode="mode"
          :label="controls.user_name.label"
          :required="true"
          @changed="updateField"
        />
      </b-col>
      <b-col sm="12" md="6" lg="3" xl="3">
        <form autocomplete="off">
          <inline-input
            :id="controls.user_password.id"
            :value="user.user_password"
            :type="'password'"
            :label="controls.user_password.label"
            :mode="mode"
            :required="controls.user_password.required"
            @changed="updateField"
          />
        </form>
      </b-col>

      <b-col sm="12" md="6" lg="3" xl="3">
        <inline-input
          :id="controls.email1.id"
          :value="user.email1"
          :label="controls.email1.label"
          :readonly="!$permitted(controls.email1.id).write"
          :mode="mode"
          :required="true"
          @changed="updateField"
        />
      </b-col>
      <b-col sm="12" md="6" lg="3" xl="3">
        <inline-input
          :id="controls.email2.id"
          :value="user.email2"
          :label="controls.email2.label"
          :mode="mode"
          :required="true"
          @changed="updateField"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="6" lg="3" xl="3">
        <inline-date-picker
          :id="controls.date_birth.id"
          :value-single="user.date_birth"
          :label="controls.date_birth.label"
          :mode="mode"
          @changed="updateDateField"
        />
      </b-col>
      <b-col sm="12" md="6" lg="3" xl="3">
        <inline-input
          :id="controls.first_name.id"
          :value="user.first_name"
          :label="controls.first_name.label"
          :mode="mode"
          @changed="updateField"
        />
      </b-col>
      <b-col sm="12" md="6" lg="3" xl="3">
        <inline-input
          :id="controls.last_name.id"
          :value="user.last_name"
          :label="controls.last_name.label"
          :mode="mode"
          @changed="updateField"
        />
      </b-col>
      <b-col sm="12" md="6" lg="3" xl="3">
        <inline-input
          :id="controls.phone_mobile.id"
          :value="user.phone_mobile"
          :label="controls.phone_mobile.label"
          :mode="mode"
          @changed="updateField"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="6" lg="3" xl="3">
        <inline-select
          :id="controls.is_admin.id"
          :value="user.is_admin"
          :label="controls.is_admin.label"
          :readonly="!$permitted(controls.is_admin.id).write"
          :options="controls.is_admin.options"
          :mode="mode"
          @changed="updateSelect"
        />
      </b-col>

      <b-col sm="12" md="6" lg="3" xl="3">
        <inline-select
          :id="controls.role.id"
          :value="user.role"
          :label="controls.role.label"
          :readonly="!$permitted(controls.role.id).write"
          :options="controls.role.options"
          :required="true"
          :allow-empty="false"
          :mode="mode"
          @changed="updateSelect"
        />
      </b-col>
      <b-col sm="12" md="6" lg="3" xl="3">
        <inline-input
          :id="controls.department.id"
          :value="user.department"
          :label="controls.department.label"
          :readonly="!$permitted(controls.department.id).write"
          :mode="mode"
          @changed="updateField"
        />
      </b-col>

      <b-col sm="12" md="6" lg="3" xl="3">
        <div class="form-group">
          <label class="col-form-label" for="groups">Groups</label>
          <div class="form-group">
            <textarea
              rows="2"
              v-if="
                mode === $constants.FORM_MODE.VIEW && controls.groups.readonly
              "
              :readonly="
                mode === $constants.FORM_MODE.VIEW
                  ? controls.groups.readonly
                  : false
              "
              type="text"
              class="form-control"
              id="groups-input"
              :value="controls.groups.as_string"
              @change="changeField($event)"
              @dblclick="dblclickField($event)"
            />

            <multiselect
              id="groups-select"
              v-if="!controls.groups.readonly"
              :close-on-select="false"
              track-by="groupid"
              label="groupname"
              v-model="controls.groups.value"
              :options="controls.groups.options"
              deselect-label=""
              select-label=""
              :multiple="true"
              @input="changeSelect"
              @close="closeSelect"
            />
          </div>
        </div>
      </b-col>
    </b-row>

    <h5>User address</h5>
    <hr />

    <b-row>
      <b-col sm="12" md="12" lg="6" xl="6">
        <inline-input
          :id="controls.address_street.id"
          :value="user.address_street"
          :label="controls.address_street.label"
          :readonly="!$permitted(controls.address_street.id).write"
          :mode="mode"
          @changed="updateField"
        />
      </b-col>

      <b-col sm="12" md="6" lg="3" xl="3">
        <inline-input
          :id="controls.address_country.id"
          :value="user.address_country"
          :label="controls.address_country.label"
          :readonly="!$permitted(controls.address_country.id).write"
          :mode="mode"
          @changed="updateField"
        />
      </b-col>
      <b-col sm="12" md="6" lg="3" xl="3">
        <inline-input
          :id="controls.address_city.id"
          :value="user.address_city"
          :label="controls.address_city.label"
          :readonly="!$permitted(controls.address_city.id).write"
          :mode="mode"
          @changed="updateField"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="6" lg="3" xl="3">
        <inline-input
          :id="controls.address_state.id"
          :value="user.address_state"
          :label="controls.address_state.label"
          :readonly="!$permitted(controls.address_state.id).write"
          :mode="mode"
          @changed="updateField"
        />
      </b-col>
      <b-col sm="12" md="6" lg="3" xl="3">
        <inline-input
          :id="controls.address_postalcode.id"
          :value="user.address_postalcode"
          :label="controls.address_postalcode.label"
          :readonly="!$permitted(controls.address_postalcode.id).write"
          :mode="mode"
          @changed="updateField"
        />
      </b-col>
    </b-row>

    <h5>More information</h5>
    <hr />
    <b-row>
      <b-col sm="12" md="6" lg="3" xl="3">
        <inline-input
          :id="controls.phone_work.id"
          :value="user.phone_work"
          :label="controls.phone_work.label"
          :readonly="!$permitted(controls.phone_work.id).write"
          :mode="mode"
          @changed="updateField"
        />
      </b-col>
      <b-col sm="12" md="6" lg="3" xl="3">
        <inline-input
          :id="controls.phone_home.id"
          :value="user.phone_home"
          :label="controls.phone_home.label"
          :readonly="!$permitted(controls.phone_home.id).write"
          :mode="mode"
          @changed="updateField"
        />
      </b-col>

      <b-col sm="12" md="6" lg="3" xl="3">
        <inline-input
          :id="controls.title.id"
          :value="user.title"
          :label="controls.title.label"
          :readonly="!$permitted(controls.title.id).write"
          :mode="mode"
          @changed="updateField"
        />
      </b-col>
      <b-col sm="12" md="6" lg="3" xl="3">
        <inline-select
          :id="controls.reports_to.id"
          :value="user.reports_to"
          :label="controls.reports_to.label"
          :readonly="!$permitted(controls.reports_to.id).write"
          :options="controls.reports_to.options"
          :mode="mode"
          @changed="updateSelect"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="6" lg="6" xl="6">
        <inline-input
          :id="controls.description.id"
          :value="user.description"
          :label="controls.description.label"
          :readonly="!$permitted(controls.description.id).write"
          :mode="mode"
          @changed="updateField"
        />
      </b-col>
    </b-row>
    <hr />

    <form-submission-actions
      :mode="mode"
      :loading="{ save: saveInProgress, saveAndView: saveAndViewInProgress }"
      :buttons-visibility="{
        previous: $customTable.getPrevKey($route.meta.module, id),
        next: $customTable.getNextKey($route.meta.module, id)
      }"
      @previous-item="
        $router.push({
          name: $route.name,
          params: {
            action: $route.params.action,
            id: $customTable.getPrevKey($route.meta.module, id)
          }
        })
      "
      @next-item="
        $router.push({
          name: $route.name,
          params: {
            action: $route.params.action,
            id: $customTable.getNextKey($route.meta.module, id)
          }
        })
      "
      @save="
        save('tabular').then(response =>
          response ? $router.push({ name: 'Users' }) : false
        )
      "
      @save-and-view="
        save('view').then(response =>
          response
            ? $router.push({
                name: 'User submission',
                params: { action: 'view', id: response }
              })
            : false
        )
      "
      @edit="
        $router.push({
          name: 'User submission',
          params: { action: 'edit', id: id }
        })
      "
      @back="$router.push($store.getters['router/previousRoute'])"
    />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import InlineInput from "@/components/InlineInput";

import InlineSelect from "@/components/InlineSelect";

import FormSubmissionActions from "@/components/FormSubmissionActions";

import { required } from "vuelidate/lib/validators";

export default {
  props: {
    id: {
      type: [String, Number],
      default: undefined
    },
    mode: {
      type: [String, Number],
      default: undefined
    },
    isProfile: { type: Boolean, default: false }
  },
  name: "UserDetailsForm",
  components: {
    Multiselect,
    InlineInput,
    InlineSelect,
    FormSubmissionActions
  },
  data: function() {
    return {
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      controls: {
        buttons: {
          edit: {
            id: "button:details_edit"
          },
          save: {
            id: "button:details_save"
          }
        },

        role: {
          id: "select:role",
          label: "Role",
          options: [],
          //trackBy: { id: "roleid", label: "rolename"},
          changed: false,
          readonly: false
        },

        user_name: {
          id: "input:user_name",
          label: "User Name",
          changed: false,
          readonly: false
        },
        user_password: {
          id: "input:user_password",
          label: "Password",
          changed: false,
          readonly: false,
          required: true
        },
        email1: {
          id: "input:email1",
          label: "Primary Email",
          changed: false,
          readonly: false
        },
        email2: {
          id: "input:email2",
          label: "Personal Email",
          changed: false,
          readonly: false
        },
        date_birth: {
          id: "datepicker:date_birth",
          label: "Birth date",
          changed: false,
          readonly: false
        },
        first_name: {
          id: "input:first_name",
          label: "First name",
          changed: false,
          readonly: false
        },
        last_name: {
          id: "input:last_name",
          label: "Last name",
          changed: false,
          readonly: false
        },
        phone_mobile: {
          id: "input:phone_mobile",
          label: "Phone mobile",
          changed: false,
          readonly: false
        },
        department: {
          id: "input:department",
          label: "Department",
          changed: false,
          readonly: false
        },
        address_street: {
          id: "input:address_street",
          label: "Street address",
          changed: false,
          readonly: false
        },
        address_country: {
          id: "input:address_country",
          label: "Country",
          changed: false,
          readonly: false
        },
        address_city: {
          id: "input:address_city",
          label: "City",
          changed: false,
          readonly: false
        },
        address_state: {
          id: "input:address_state",
          label: "State",
          changed: false,
          readonly: false
        },
        address_postalcode: {
          id: "input:address_postalcode",
          label: "Postal code",
          changed: false,
          readonly: false
        },
        phone_work: {
          id: "input:phone_work",
          label: "Office phone",
          changed: false,
          readonly: false
        },
        phone_home: {
          id: "input:phone_home",
          label: "Home phone",
          changed: false,
          readonly: false
        },
        title: {
          id: "input:title",
          label: "Title",
          changed: false,
          readonly: false
        },
        reports_to: {
          id: "select:reports_to",
          label: "Reports to",
          options: [],
          changed: false,
          readonly: false
        },
        description: {
          id: "input:description",
          label: "Notes",
          changed: false,
          readonly: false
        },
        is_admin: {
          id: "select:is_admin",
          label: "Is Admin",
          options: [
            {
              id: "on",
              label: "on"
            },
            {
              id: "off",
              label: "off"
            }
          ],
          changed: false,
          readonly: false
        },
        groups: {
          options: [],
          value: [],
          changed: false,
          readonly: false,
          as_string: ""
        }
      },
      user: {
        id: "",
        user_name: "",
        user_password: "",
        email1: "",
        email2: "",
        date_birth: "",
        department: "",
        phone_mobile: "",

        first_name: "",
        last_name: "",
        //deleted: '',
        title: "",
        description: "",
        address_street: "",
        address_country: "",
        address_city: "",
        address_state: "",
        address_postalcode: "",
        phone_work: "",
        phone_home: "",
        is_admin: {
          id: "off",
          label: "off"
        },
        role: {
          id: "",
          label: ""
        },
        reports_to: {
          id: "",
          label: ""
        },
        groups: []
      }
    };
  },
  validations: {
    user: {
      user_name: {
        required
      },
      user_password: {
        //      required
      },
      email1: {
        required
      }
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      let self = this;

      this.updateControlsState();

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        await this.fetchUser(this.id);

        //user password does not required during editing
        this.controls.user_password.required = false;
      }

      this.$api.get("roles").then(response => {
        this.controls.role.options = response.map(item => {
          return {
            id: item.roleid,
            label: item.rolename
          };
        });
      });

      this.$api.get("users").then(response => {
        this.controls.reports_to.options = response.map(item => {
          return {
            id: item.id,
            label: item.full_name
          };
        });
      });

      this.$api.get("groups").then(response => {
        self.controls.groups.options = response;

        self.$api.get(`user/${self.id}/groups`).then(response => {
          self.controls.groups.value = response;

          self.user.groups = response;
        });
      });
    },

    edit: function() {
      this.$router.push({
        name: "User submission",
        params: {
          action: "edit",
          id: this.id
        }
      });
    },

    save(_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return Promise.resolve(false);
      }

      let self = this;

      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      let url =
        this.mode === this.$constants.FORM_MODE.CREATE
          ? "user/create"
          : "user/update";

      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      return this.$api
        .put(url, this.user)
        .then(response => {
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          self.$form.makeToastInfo(response.message);

          return response.id;
        })
        .catch(error => {
          self.$form.makeToastError(error ? error.message : "undefined");

          return Promise.resolve(false);
        });
    },
    fetchUser: async function(id) {
      let self = this;

      return this.$api
        .get(`user/${id}`)
        .then(response => {
          /*
                response.status = {
                    id: response.status,
                    label: response.status
                }
*/
          response.is_admin = {
            id: response.is_admin,
            label: response.is_admin
          };

          response.role = {
            id: response.roleid,
            label: response.rolename
          };

          response.reports_to = {
            id: response.reports_to_id,
            label: response.reports_to_name
          };

          self.user = response;
        })
        .catch(error => {
          console.log(error);

          this.$emit("message", {
            title: "Error",
            text: error.message
          });
        });
    },
    closeSelect(value, id) {
      id = id.replace(/-select|-input/g, "");

      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        this.controls[id].readonly = true;
        if (this.controls[id].changed) this.updateField(id, this.user[id]);
      }
    },
    changeField(e) {
      let field = e.currentTarget.id;
      if (this.user[field] !== e.currentTarget.value) {
        this.user[field] = e.currentTarget.value;
        this.controls[field].changed = true;
      }
    },
    changeSelect(value, id) {
      //remove postfix from element id
      let field = id.replace(/-select|-input/g, "");

      if (this.user[field] !== value) {
        this.user[field] = value;
        this.controls[field].changed = true;
      }
    },

    dblclickField(e) {
      let id = e.currentTarget.id.replace(/-select|-input/g, "");

      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        this.controls[id].readonly = !this.controls[id].readonly;
      }
    },
    updateDateField(e) {
      this.updateField(e.id, e.valueSingleAsString);
    },
    updateField(field, value) {
      let self = this;
      let data = {};
      data["id"] = this.id;
      data[field] = value;
      self.user[field] = value;

      //if control in View mode then update database
      if (this.mode === this.$constants.FORM_MODE.VIEW)
        this.$api
          .put("user/update", data)
          .then(response => {
            self.$emit("toast", {
              title: field,
              text: response.message,
              type: "warning"
            });

            self.controls[field].changed = false;
          })
          .catch(response => {
            console.log(response);

            self.$emit("toast", {
              title: field,
              text: response.message,
              type: "danger"
            });
          });
    },
    updateSelect(id, value) {
      this.updateField(id, value);
    },
    showAlert(message) {
      this.controls.alert.show = 3;
      this.controls.alert.message = message;
    },

    //outdated. should not be used with Inline input compoments
    updateControlsState() {
      let _readonly = false;

      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        _readonly = false;
      }

      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        _readonly = true;
      }

      for (var prop in this.controls) {
        if (Object.prototype.hasOwnProperty.call(this.controls, prop)) {
          this.controls[prop].readonly = _readonly;
        }
      }
    }
  },
  watch: {
    "controls.groups.value"(newValue) {
      let groups = [...new Set(newValue.map(item => item.groupname))];

      this.controls.groups.as_string = groups.join(",");

      newValue.forEach(() => {});
    },
    mode() {
      this.updateControlsState();
    }
  }
};
</script>

<style scoped>
::v-deep .readonly {
  background-color: #e8e8e873 !important;
  cursor: default !important;
}

::v-deep .form-control[readonly] {
  background-color: #fff;
  font-weight: bold;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}
/*
.disabled {
  background-color: #ccc !important;
}
*/
</style>
