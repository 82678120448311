var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('h5', [_vm._v("Salary trend")]), _c('g-chart-custom', {
    ref: "trendChart",
    attrs: {
      "title": "",
      "subtitle": "",
      "type": "LineChart",
      "loading": _vm.trendChart.isLoading,
      "data": _vm.trendChart.dataSet,
      "options": _vm.trendChart.options
    }
  })], 1), _c('b-col', [_c('h5', [_vm._v(" Changes history "), _c('clip-loader', {
    staticClass: "ml-3 mt-2",
    attrs: {
      "size": 14,
      "color": "#36D7B7",
      "loading": _vm.historyTable.isLoading
    }
  })], 1), _vm.historyTable.dataSet.length > 0 ? _c('v-client-table', {
    ref: "historyTable",
    attrs: {
      "data": _vm.historyTable.dataSet,
      "options": _vm.historyTable.options,
      "columns": _vm.historyTable.columns
    }
  }) : _vm._e()], 1)], 1), _c('hr'), _c('form', [_c('h5', [_vm._v("Salary details")]), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.salarystart.id,
      "value-single": _vm.salary.salarystart,
      "label": _vm.controls.salarystart.label,
      "readonly": !_vm.$permitted(_vm.controls.salarystart.id).write,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.empstatus.id,
      "value": _vm.salary.empstatus,
      "label": _vm.controls.empstatus.label,
      "readonly": _vm.mode === _vm.$constants.FORM_MODE.READONLY,
      "options": _vm.controls.empstatus.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('inline-select', {
    ref: "category",
    attrs: {
      "id": _vm.controls.category.id,
      "value": _vm.salary.category,
      "label": _vm.controls.category.label,
      "readonly": _vm.mode === _vm.$constants.FORM_MODE.READONLY,
      "options": _vm.controls.category.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSalaryCategory
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_vm.salary.category.label === 'Hourly' ? _c('inline-input', {
    ref: "ratehour",
    attrs: {
      "id": _vm.controls.ratehour.id,
      "value": _vm.salary.ratehour,
      "label": _vm.controls.ratehour.label,
      "readonly": _vm.mode === _vm.$constants.FORM_MODE.READONLY,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSalaryRate
    }
  }) : _vm._e(), _vm.salary.category.label === 'Exempt' ? _c('inline-input', {
    ref: "rateyear",
    attrs: {
      "id": _vm.controls.rateyear.id,
      "value": _vm.salary.rateyear,
      "label": _vm.controls.rateyear.label,
      "readonly": _vm.mode === _vm.$constants.FORM_MODE.READONLY,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSalaryRate
    }
  }) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.insurance.id,
      "value": _vm.salary.insurance,
      "label": _vm.controls.insurance.label,
      "readonly": _vm.mode === _vm.$constants.FORM_MODE.READONLY,
      "options": _vm.controls.insurance.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.stipend.id,
      "value": _vm.salary.stipend,
      "label": _vm.controls.stipend.label,
      "readonly": _vm.mode === _vm.$constants.FORM_MODE.READONLY,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.notes.id,
      "value": _vm.salary.notes,
      "label": _vm.controls.notes.label,
      "readonly": _vm.mode === _vm.$constants.FORM_MODE.READONLY,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('h5', [_c('a', {
    attrs: {
      "href": '#/user-management/bonus-payments'
    }
  }, [_vm._v("Bonus payments")])]), _c('TableWrapper', {
    ref: _vm.models.userManagement.bonusPayments.name,
    attrs: {
      "model": _vm.models.userManagement.bonusPayments,
      "parent-id": _vm.id,
      "columns": ['Bonus Date', 'Amount', 'Description'],
      "read-only": true,
      "perPage": 5,
      "filter-by-column": false
    }
  })], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', [_c('h5', [_vm._v("Vacation details (VL)")]), _c('hr'), _c('div', [_vm._v("Rollover hours: " + _vm._s(_vm.vacationData.rollover))]), _c('div', [_vm._v("Accrued hours: " + _vm._s(_vm.vacationData.accrued))]), _c('div', [_vm._v("Used paid hours: " + _vm._s(_vm.vacationData.usedPaid))]), _c('div', [_vm._v("Not used hours: " + _vm._s(_vm.vacationData.notUsed))]), _c('div', [_vm._v("Used unpaid hours: " + _vm._s(_vm.vacationData.usedUnpaid))]), _c('div', [_vm._v("Available hours: " + _vm._s(_vm.vacationData.available))])]), _c('b-col', [_c('h5', [_vm._v("Sick details (PSL)")]), _c('hr'), _c('div', [_vm._v("Rollover hours: " + _vm._s(_vm.sickData.rollover))]), _c('div', [_vm._v("Accrued hours: " + _vm._s(_vm.sickData.accrued))]), _c('div', [_vm._v("Used paid hours: " + _vm._s(_vm.sickData.usedPaid))]), _c('div', [_vm._v("Not used hours: " + _vm._s(_vm.sickData.notUsed))]), _c('div', [_vm._v("Used unpaid hours: " + _vm._s(_vm.sickData.usedUnpaid))]), _c('div', [_vm._v("Available hours: " + _vm._s(_vm.sickData.available))])]), _c('b-col', [_c('h5', [_vm._v("Personal leave details (PLA)")]), _c('hr'), _c('div', [_vm._v("Rollover hours: " + _vm._s(_vm.PLAData.rollover))]), _c('div', [_vm._v("Accrued hours: " + _vm._s(_vm.PLAData.accrued))]), _c('div', [_vm._v("Used paid hours: " + _vm._s(_vm.PLAData.usedPaid))]), _c('div', [_vm._v("Not used hours: " + _vm._s(_vm.PLAData.notUsed))]), _c('div', [_vm._v("Used unpaid hours: " + _vm._s(_vm.PLAData.usedUnpaid))]), _c('div', [_vm._v("Available hours: " + _vm._s(_vm.PLAData.available))])])], 1)], 1), _c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
      }
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Users'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'User submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'User submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }